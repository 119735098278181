import React from 'react';
import '../scss/main.scss';


const Projects=(props)=>
{
  return(
    <div  id="Projects" className="Projects">
      <div className="projects-container">
        <h1>PROJECTS</h1>
          <div className="project-flex">
            <div className="project-row">
              <div className="project">

              </div>
              <div className="project">

              </div>
              <div className="project">

              </div>
            </div>

            <div className="project-row">
              <div className="project">

              </div>
              <div className="project">

              </div>
              <div className="project">
                
              </div>
            </div>
          </div>
      </div>
    </div>);
}
 
export default Projects;